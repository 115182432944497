@import '../sass/sb-admin-2.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import './kpi.scss';
@import './form-login.scss';

.is-clickable:hover,
.rdt_TableRow:hover,
.highcharts-point:hover {
    cursor: pointer;
}

label {
    text-transform: capitalize;
    margin-top: 5px;
}

nav#topbar {
    background-color: $white;
    color: $gray-600;
    box-shadow: 0 .15rem 1.75rem 0 $light-black;

    .row {
        width: 100%;

        .col {
            p {
                margin: 0;
                color: $gray-600;
                font-size: 12px;
            }
        }
    }

    .btn-link {
        text-decoration: none;
        color: $gray-600;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .btn:focus {
        box-shadow: none;
    }
}

nav#topbar {
    div.container-fluid {
        button.btn.btn-link {
            padding-left: 0px;
            font-size: 12px;
        }

        div.d-flex.justify-content-end {
            width: 100%;
        }

        .topbar-divider {
            width: 0;
            border-right: 1px solid $light-gray;
            height: 2.375rem;
            margin: auto 1rem;
        }
    }
}

#user-menu {
    left: -45px;
}

// Sidebar - brand
span.navbar-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;

    a {
        img {
            display: inline-block;
        }
    }

    a,
    a:hover,
    a:focus,
    a:active {
        color: $blue-light;
        text-transform: uppercase;
        font-weight: bold;
    }
}

// Sidebar - menu
div.menu {
    background-color: $white;

    .menu-divider {
        border-top: 1px solid $white;
        margin: 0 1rem 1rem;
    }

    ul {
        padding-left: 0px;

        li {
            list-style: none outside none;

            a {
                text-align: left;
                text-transform: uppercase;
                color: $white;
                font-size: .85rem;
                font-weight: 700;
                padding: 1rem;

                svg {
                    color: $white;
                    margin-right: 12px;
                    width: 30px;
                }
            }

            a.nav-link.active,
            a.nav-link:hover {
                color: $white;
                background-color: $gray-900;

                svg {
                    color: $white;
                }
            }

            svg+svg {
                float: right;
                margin-top: 3px;
            }
        }
    }

    div.collapse.show {
        background-color: $white;
        width: 90%;
        padding: 10px;
        margin: 0 auto;
        border-radius: 5px;
    }
}

// Pagina Dashboard - Processi - Cerca COC
.row {

    .col,
    .col-lg-12,
    .col-lg-8,
    .col-lg-4 {
        padding: 0 20px;

        h1 {
            text-transform: uppercase;
            font-size: 29px;
            color: $black;
            margin: 19px 0;
        }

        h5 {
            text-transform: capitalize;
        }

        p {
            color: $black;
            margin-bottom: 0;
        }

        .card {
            .card-header {
                padding: 0;

                .card-title {
                    padding: 10px;
                    background-color: $yellow;
                    color: $black;
                    margin: 0;
                }
            }
        }
    }
}

// Pagina Assistenza
.row {
    .col {
        color: $black;

        .accordion {
            .accordion-item {
                color: $black;

                .accordion-header {
                    .accordion-button {
                        color: $black;
                        padding: 10px;
                        background-color: $yellow;
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                    .accordion-button:not(.collapsed) {
                        color: $black;
                        box-shadow: none;
                    }
                }

                .accordion-body {
                    p {
                        border-left: 3px solid $yellow;
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}


.form-coc {

    .col,
    .col-12 {
        margin-top: 20px;
        text-align: end;

        .btn {
            width: 100%;
            background-color: $yellow;
            border: 2px solid $yellow;
        }
    }

    .col:first-child,
    .col-12:first-child {
        text-align: left;
    }
}

div.loading {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: auto;
    max-width: 536px;

    p {
        margin-top: 15px;
    }
}

footer {
    text-align: center;
    font-size: 88%;
    margin-top: 20px;
}

#wrapper {
    #content-wrapper.content-wrapper-guest {
        background-color: $white;
    }
}

@include media-breakpoint-down(md) {

    // Sidebar - menu
    div.menu {

        ul {

            li {

                a {
                    text-align: center;
                    font-size: .65rem;

                    svg {
                        display: block;
                        margin: 0px auto 7px auto;
                    }
                }


            }
        }

    }

}