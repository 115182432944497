footer.sticky-footer {
  padding: 2rem 0;
  .container-fluid {
    .row {
      .col:first-child {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
          line-height: 1;
          font-size: 0.8rem;
        }
      }
      .col {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img {
          display: inline-block;
          height: 76px;
          width: auto;
        }
      }
    }
  }
}

body.sidebar-toggled {
  footer.sticky-footer {
    width: 100%;
  }
}
