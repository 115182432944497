form.form-login {
    margin: 100px auto;

    .container {
        .row {
            display: flex;
            justify-content: center;
            align-items: center;

            .col-md-6,
            .col-lg-4 {
                box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15);

                .row {
                    padding: 1.25rem;

                    .col-lg-12 {
                        margin-bottom: 1rem;
                        padding: 0;

                        label {
                            color: $black;
                        }
                    }

                    .col-lg-12:nth-child(3) {
                        margin-bottom: 0;
                        padding: 0;

                        button {
                            margin: 10px 0;
                            background-color: $yellow;
                            border: 2px solid $yellow;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    form.form-login {
        .container {
            .row {

                .col-md-6,
                .col-lg-4 {
                    box-shadow: none;

                }
            }
        }
    }
}