.dashboard {
    .row {
        .col-md-12 {
            display: block;
        }
    }

    .row:nth-child(2) {
        padding: 20px 0px;

        .col {
            display: flex;
            justify-content: center;

            .kpi {
                flex: 1;

                .card-body {
                    padding: 1.25rem 2rem 2rem 2rem;

                    .row {
                        padding: 0;
                        .col {
                            color: $white;
                        }

                        .col:first-child {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: center;

                            p:first-child {
                                text-transform: uppercase;
                                font-size: 1.75rem;
                                color: $white;
                                margin-bottom: 0px;
                                line-height: 28px;
                            }

                            p {
                                font-size: 2.5rem;
                                color: $white;
                                margin-bottom: 0;
                            }
                        }

                        .col:nth-child(2) {
                            display: flex;
                            flex-direction: column;
                            text-align: right;
                            flex: 0 0 auto;
                            max-width: 100%;
                            width: auto;
                            color: $white;

                            .icon {
                                font-size: 8em;
                                color: $white-transparent;
                                text-align: end;
                            }

                            p {
                                position: relative;
                                top: -34px;
                                font-size: 14px;
                                text-align: center;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }

        .col:nth-child(3) {
            .kpi {
                cursor: pointer;
            }
        }

    }
}

@media only screen and (max-width: 1052px) {
    .dashboard { 
        .row:nth-child(2) {
            .col {
                margin-top: 20px;
            }
        }
    }
}